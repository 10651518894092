import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      id='comparison-modal'
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton />
      <img-comparison-slider>
        <img
          slot='first'
          alt='Before'
          src={props.beforeImage}
          className='enlarged-ba-photos'
        ></img>
        <img
          slot='second'
          alt='After'
          src={props.afterImage}
          className='enlarged-ba-photos'
        ></img>
      </img-comparison-slider>
    </Modal>
  );
}

function BeforeAfterComparison({ beforeImage, afterImage }) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className='col-6' id='sales-presentation text-center'>
      <img-comparison-slider>
        <img
          slot='first'
          alt='Before'
          src={beforeImage}
          className='ba-photos'
        ></img>
        <img
          slot='second'
          alt='After'
          src={afterImage}
          className='ba-photos'
        ></img>
      </img-comparison-slider>
      <div className='d-block d-flex justify-content-center'>
        <Button
          variant='primary'
          onClick={() => setModalShow(true)}
          className='btn-slide btn-blue'
        >
          Enlarge
        </Button>
      </div>
      <MyVerticallyCenteredModal
        beforeImage={beforeImage}
        afterImage={afterImage}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default BeforeAfterComparison;
