import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import './storeRankCalculator.css';
import MilanLogoWide from '../shared/MilanLogoWide';

function StoreRankCalculatorHome() {
  let navigate = useNavigate();
  const initialValues = {
    soldPercentage: '',
    avgSale: '',
    yourUpsell: '',
    upsellGoal: '',
  };

  //it's used to calculate the final score
  const [values, setValues] = useState(initialValues);
  const [finalScore, setFinalScore] = useState();
  const [finalScoreStyle, setFinalScoreStyle] = useState({
    fontWeight: '300',
    fontColor: '#0088ab',
  });

  //this sets the value user input in the state called 'values'
  //makes sures that the user cannot input any characters that are not allowed
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'soldPercentage' && value.length <= 3) {
      //remove letters and special characters so we don't get error.
      setValues({ ...values, [name]: value.replace(/\D+/g, '') });
    } else if (name !== 'soldPercentage' && value.length <= 8) {
      //remove letters and special characters so we don't get error.
      setValues({ ...values, [name]: value.replace(/\D+/g, '') });
    }
  };

  const resetValues = () => {
    setValues(initialValues);
  };

  useEffect(() => {
    let avg_sale_var = parseInt(values.avgSale);
    let your_upsell_var = parseInt(values.yourUpsell);
    let upsell_goal_var = parseInt(values.upsellGoal);

    //THIS IS THE SCORE WE GET FOR WHAT THE USER INPUTS
    let sold_percent_score = 0,
      avg_sale_score = 0,
      upsell_score = 0,
      finalScoreVar;

    //when all four inputs are not empty then run the condition
    if (
      values.soldPercentage !== '' &&
      values.avgSale !== '' &&
      values.yourUpsell !== '' &&
      values.upsellGoal !== ''
    ) {
      let multiplier = (11 / 7) * values.soldPercentage - 17; // changed to 11/7 since we have the percentage form, not the decimal form
      if (values.soldPercentage <= 11) {
        sold_percent_score = 0;
      } else if (values.soldPercentage >= 77) {
        sold_percent_score = 80;
      } else {
        sold_percent_score =
          (Math.floor(multiplier) * values.soldPercentage) / 100; // a bit different than the code I provided because the percentage is in percent form, not decimal
      }

      // if (avg_sale_var < 4500) {
      //   avg_sale_score = 0;
      // } else if (avg_sale_var >= 4500 && avg_sale_var < 4550) {
      //   avg_sale_score = 1;
      // } else if (avg_sale_var >= 4550 && avg_sale_var < 4600) {
      //   avg_sale_score = 2;
      // } else if (avg_sale_var >= 4600 && avg_sale_var < 4650) {
      //   avg_sale_score = 3;
      // } else if (avg_sale_var >= 4650 && avg_sale_var < 4700) {
      //   avg_sale_score = 4;
      // } else if (avg_sale_var >= 4700 && avg_sale_var < 4750) {
      //   avg_sale_score = 5;
      // } else if (avg_sale_var >= 4750 && avg_sale_var < 4800) {
      //   avg_sale_score = 6;
      // } else if (avg_sale_var >= 4800 && avg_sale_var < 4850) {
      //   avg_sale_score = 7;
      // } else if (avg_sale_var >= 4850 && avg_sale_var < 4900) {
      //   avg_sale_score = 8;
      // } else if (avg_sale_var >= 4900 && avg_sale_var < 4950) {
      //   avg_sale_score = 9;
      // } else if (avg_sale_var >= 4950) {
      //   avg_sale_score = 10;
      // }

      if (avg_sale_var < 4700) {
        avg_sale_score = 0;
      } else if (avg_sale_var >= 4700 && avg_sale_var < 4750) {
        avg_sale_score = 1;
      } else if (avg_sale_var >= 4750 && avg_sale_var < 4800) {
        avg_sale_score = 2;
      } else if (avg_sale_var >= 4800 && avg_sale_var < 4850) {
        avg_sale_score = 3;
      } else if (avg_sale_var >= 4850 && avg_sale_var < 4900) {
        avg_sale_score = 4;
      } else if (avg_sale_var >= 4900 && avg_sale_var < 4950) {
        avg_sale_score = 5;
      } else if (avg_sale_var >= 4950 && avg_sale_var < 5000) {
        avg_sale_score = 6;
      } else if (avg_sale_var >= 5000 && avg_sale_var < 5050) {
        avg_sale_score = 7;
      } else if (avg_sale_var >= 5050 && avg_sale_var < 5100) {
        avg_sale_score = 8;
      } else if (avg_sale_var >= 5100 && avg_sale_var < 5150) {
        avg_sale_score = 9;
      } else if (avg_sale_var >= 5150) {
        avg_sale_score = 10;
      }

      //this gives the percentage
      let upsell_temp = (your_upsell_var / upsell_goal_var) * 100;

      //new way to accomdate if the upsell goal is 0 then the score will automatically be 10
      //if the upsell goal is 0, we'll automatically give 10 points for upsell_score
      if (upsell_goal_var === 0) {
        upsell_score = 10;
      } //but if the upsell_goal_var is not 0
      else if (upsell_goal_var !== 0) {
        if (upsell_temp < 10) {
          upsell_score = 0;
        } else if (upsell_temp < 20) {
          upsell_score = 1;
        } else if (upsell_temp < 30) {
          upsell_score = 2;
        } else if (upsell_temp < 40) {
          upsell_score = 3;
        } else if (upsell_temp < 50) {
          upsell_score = 4;
        } else if (upsell_temp < 60) {
          upsell_score = 5;
        } else if (upsell_temp < 70) {
          upsell_score = 6;
        } else if (upsell_temp < 80) {
          upsell_score = 7;
        } else if (upsell_temp < 90) {
          upsell_score = 8;
        } else if (upsell_temp < 100) {
          upsell_score = 9;
        } else if (upsell_temp >= 100) {
          upsell_score = 10;
        }
      }

      finalScoreVar = sold_percent_score + avg_sale_score + upsell_score;
    } else {
      finalScoreVar = 0;
    }

    if (finalScoreVar < 70 && finalScoreVar !== 0) {
      setFinalScoreStyle({ fontWeight: '400', fontColor: '#f13a1a' });
    } else if (finalScoreVar >= 70 && finalScore <= 85) {
      setFinalScoreStyle({ fontWeight: '400' });
    } else if (finalScoreVar > 85 && finalScore <= 100) {
      setFinalScoreStyle({ fontWeight: '400', fontColor: '#0ACCA7' });
    } else {
      setFinalScoreStyle({ fontWeight: '300', fontColor: '#0088ab' });
    }

    if (finalScoreVar > 100) {
      setFinalScore(100);
    } else {
      setFinalScore(Math.round(finalScoreVar));
    }
  }, [values, finalScore]);

  return (
    <div id='store-rank'>
      <div className='home-btn m-2 text-center d-flex justify-content-center pt-1'>
        <button
          onClick={() => {
            navigate('/');
          }}
        >
          <FontAwesomeIcon icon={faHouse} className='fa-xl' />
        </button>
      </div>
      <div
        className='bg-turquoise text-center d-flex justify-content-center'
        style={{ height: '4rem' }}
      >
        <MilanLogoWide />
      </div>
      <div className='container'>
        <div className='mt-5 d-flex justify-content-center'>
          <div className='row width-limit d-flex justify-content-center bg-light-turquoise border-turquoise pb-3'>
            <div className='width-limit-percent'>
              <h1 className='text-center my-4 font-dark-turquoise text-uppercase'>
                <strong>Store Rank Calculator</strong>
              </h1>
              <div className='d-flex'>
                {/* Sold Percentage % */}
                <div className='w-100 mb-3'>
                  <p className='mb-1'>Sold Percentage</p>
                  <div className='percentage-sign'>
                    <input
                      className='form-control d-block'
                      type='text'
                      name='soldPercentage'
                      value={values.soldPercentage}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                {/* Average Sale $ */}
                <div className='w-100 mb-3'>
                  <p className='mb-1'>Average Sale</p>
                  <div className='dollar-sign'>
                    <input
                      type='text'
                      className='form-control'
                      name='avgSale'
                      value={values.avgSale.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                      )}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                {/* Your Upsells $ */}
                <div className='w-100 mb-3'>
                  <p className='mb-1'>Your Upsells</p>
                  <div className='dollar-sign'>
                    <input
                      type='text'
                      className='form-control'
                      name='yourUpsell'
                      value={values.yourUpsell.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                      )}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                {/* Upsell Goal $ */}
                <div className='w-100 mb-3'>
                  <p className='mb-1'>Upsell Goal</p>
                  <div className='dollar-sign'>
                    <input
                      type='text'
                      className='form-control'
                      name='upsellGoal'
                      value={values.upsellGoal.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                      )}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <h2
                className='mt-2 mb-3 text-center font-dark-turquoise'
                style={{
                  color: finalScoreStyle.fontColor,
                  fontWeight: finalScoreStyle.fontWeight,
                }}
              >
                Your Score<sup>4</sup>: {finalScore}
              </h2>
            </div>
            <div className='milan-fp'>
              <p>
                {/* <sup>1</sup>80 Pts are awarded for Sold Percentage over 76% (Sold % Goal = 71%) */}
                <sup>1</sup>65 Pts are awarded for achieving 70% Conversion
              </p>
              <p>
                <sup>2</sup>10 Pts are awarded for Average Sale over $5,150
              </p>
              <p>
                <sup>3</sup>10 Pts are awarded for hitting your upsell goal
              </p>
              <p>
                <sup>4</sup>100 is the maximum score
              </p>
            </div>
          </div>
        </div>
        <div className='mt-4 d-flex justify-content-center'>
          <div className='row width-limit d-flex justify-content-center'>
            <button
              type='button'
              className='btn btn-primary milan-btn'
              onClick={resetValues}
              style={{ maxWidth: '100px' }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StoreRankCalculatorHome;

// ------ OLD LOGIC ------
// if (values.soldPercentage <= 25) {
//   sold_percent_score = 0;
// } else if (values.soldPercentage <= 35) {
//   sold_percent_score = 30;
// } else if (values.soldPercentage <= 45) {
//   sold_percent_score = 40;
// } else if (values.soldPercentage <= 55) {
//   sold_percent_score = 50;
// } else if (values.soldPercentage <= 60) {
//   sold_percent_score = 55;
// } else if (values.soldPercentage <= 65) {
//   sold_percent_score = 58;
// } else if (values.soldPercentage <= 67) {
//   sold_percent_score = 61;
// } else if (values.soldPercentage <= 69) {
//   sold_percent_score = 63;
// } else if (values.soldPercentage <= 70) {
//   sold_percent_score = 65;
// } else if (values.soldPercentage <= 72) {
//   sold_percent_score = 69;
// } else if (values.soldPercentage <= 74) {
//   sold_percent_score = 72;
// } else if (values.soldPercentage <= 76) {
//   sold_percent_score = 76;
// } else if (values.soldPercentage >= 77) {
//   sold_percent_score = 80;
// }
