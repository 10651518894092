import React from 'react';
import MILAN_LOGO_WIDE from '../../assets/Milan_Logo_White_Wide.svg';

function MilanLogoWide() {
  return (
    <img
      src={MILAN_LOGO_WIDE}
      alt='Milan Logo Wide'
      style={{ maxWidth: '340px' }}
    />
  );
}

export default MilanLogoWide;
