import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import SalesPresentationHome from './components/salesPresentation/SalesPresentationHome';
import SalesPresentationHome30 from './components/salesPresentation/SalesPresentationHome30';
import StoreRankCalculatorHome from './components/storeRankCalculator/StoreRankCalculatorHome';
import UpsellCommisionCalcHome from './components/upsellCommissionCalculator/UpsellCommisionCalcHome';
import BalanceCalculator from './components/balanceCalculator/BalanceCalculator';
import ErrorPage from './components/ErrorPage';

function App() {
  return (
    <Router>
      {/* if you want navbar, put it here */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/upsell' element={<UpsellCommisionCalcHome />} />
        <Route path='/salespresentation' element={<SalesPresentationHome />} />
        <Route
          path='/salespresentation30'
          element={<SalesPresentationHome30 />}
        />
        <Route
          path='/storerankcalculator'
          element={<StoreRankCalculatorHome />}
        />
        <Route path='/balancecalculator' element={<BalanceCalculator />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
