import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import MilanLogoWide from '../shared/MilanLogoWide';

function BalanceCalculator() {
  let navigate = useNavigate();

  const initialValues = {
    loanBalance: '',
    discount: '.1',
  };

  const [values, setValues] = useState(initialValues);
  const [savingsDisplay, setSavingsDisplay] = useState(0);
  const [paidInFullDisplay, setPaidInFullDisplay] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name)
    if (name === 'discount') {
      setValues((prevValues) => ({
        ...prevValues,
        discount: value,
      }));

      // console.log("check for discount", values)
    }
    if (name !== 'discount' && value.length <= 6) {
      setValues({ ...values, [name]: value.replace(/\D+/g, '') });
    }
  };

  useEffect(() => {
    let savingsVal = 0;
    let paidInFullVal = 0;
    // console.log("changed!");
    // console.log(values);

    if (values.loanBalance !== '') {
      savingsVal = values.loanBalance * values.discount;
      // console.log("savingsVal", savingsVal)
      paidInFullVal = values.loanBalance - savingsVal;
      // console.log("paidInFullVal", paidInFullVal)
    }

    setSavingsDisplay(savingsVal.toFixed(2));
    setPaidInFullDisplay(paidInFullVal.toFixed(2));
  }, [values]);

  return (
    <div id='store-rank'>
      {/* Navbar */}
      <div className='home-btn m-2 text-center d-flex justify-content-center pt-1'>
        <button
          onClick={() => {
            navigate('/');
          }}
        >
          <FontAwesomeIcon icon={faHouse} className='fa-xl' />
        </button>
      </div>
      <div
        className='bg-turquoise text-center d-flex justify-content-center'
        style={{ height: '4rem' }}
      >
        <MilanLogoWide />
      </div>

      <div className='mt-5 d-flex justify-content-center'>
        <div className='row width-limit d-flex justify-content-center bg-light-turquoise border-turquoise pb-3'>
          <div className='width-limit-percent'>
            <h1 className='text-center my-4 font-dark-turquoise text-uppercase'>
              <strong>Balance Calculator</strong>
            </h1>
            <div className='d-flex'>
              <div className='w-100 mb-3'>
                <p className='mb-1'>Current Loan Balance</p>
                <div className='dollar-sign'>
                  <input
                    className='form-control'
                    type='text'
                    name='loanBalance'
                    value={values.loanBalance.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ','
                    )}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-center'>
              <div className='w-100 mb-3'>
                <p className='mb-1'>Discount</p>
                <p className='fw-bold'>10%</p>
                {/* <select className="form-select form-control" aria-label="Default select example" name="discount" onChange={handleChange}>
                  <option defaultValue value=".05">
                    5%
                  </option>
                  <option value=".1">10%</option>
                </select> */}
              </div>
            </div>

            <div className='d-flex'>
              <div className='w-100 mb-3'>
                <p className='mb-1'>
                  Savings ={' '}
                  <span className='fw-bold' style={{ color: '#00A5B4' }}>
                    {savingsDisplay}
                  </span>
                </p>
                {/* <p>{savingsDisplay}</p> */}
              </div>
            </div>
            <div className='d-flex'>
              <div className='w-100 mb-3'>
                <p className='mb-1'>
                  Balance to be Paid in Full ={' '}
                  <span className='fw-bold' style={{ color: '#F13A1A' }}>
                    {paidInFullDisplay}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalanceCalculator;
