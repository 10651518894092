import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import BeforeAfterComparison from './BeforeAfterComparison.js';
import './salesPresentationHome.css';

import image1 from './images/1.webp';
import image2 from './images/2.webp';
import image3 from './images/3.webp';
import image4 from './images/4.webp';
import image5 from './images/5.webp';
import image6 from './images/6.webp';
import image7 from './images/7.webp';
import image8 from './images/8.webp';
import image9 from './images/9.webp';
import image10 from './images/10.webp';
// import image11 from './images/11.webp';
import image12 from './images/12.webp';
import image13 from './images/13.webp';
import image14 from './images/14.webp';
import image15 from './images/15.webp';
import image16 from './images/16.webp';
import image17 from './images/17.webp';
import image18 from './images/18.webp';
import image19 from './images/19.webp';
import image20 from './images/20.webp';
import image21 from './images/21.webp';

import demoVideo from './videos/ZimmerChiller_TreatmentVideo.mp4';

import ba_lip_after from './images/beforeAfterPictures/ba-slide-1/lip_after.webp';
import ba_lip_before from './images/beforeAfterPictures/ba-slide-1/lip_before.webp';
import face_left_after from './images/beforeAfterPictures/ba-slide-1/face_left_after.webp';
import face_left_before from './images/beforeAfterPictures/ba-slide-1/face_left_before.webp';
import face_right_after from './images/beforeAfterPictures/ba-slide-1/face_right_after.webp';
import face_right_before from './images/beforeAfterPictures/ba-slide-1/face_right_before.webp';
import face_left2_after from './images/beforeAfterPictures/ba-slide-1/face_left2_after.webp';
import face_left2_before from './images/beforeAfterPictures/ba-slide-1/face_left2_before.webp';

import ax_after from './images/beforeAfterPictures/ba-slide-2/ax_after.webp';
import ax_before from './images/beforeAfterPictures/ba-slide-2/ax_before.webp';
import ax_dark_after from './images/beforeAfterPictures/ba-slide-2/ax_dark_after.webp';
import ax_dark_before from './images/beforeAfterPictures/ba-slide-2/ax_dark_before.webp';
import underarms3_after from './images/beforeAfterPictures/ba-slide-2/underarms3_after.webp';
import underarms3_before from './images/beforeAfterPictures/ba-slide-2/underarms3_before.webp';
import underarms4_after from './images/beforeAfterPictures/ba-slide-2/underarms4_after.webp';
import underarms4_before from './images/beforeAfterPictures/ba-slide-2/underarms4_before.webp';

import underarms5_after from './images/beforeAfterPictures/ba-slide-3/underarms5_after.webp';
import underarms5_before from './images/beforeAfterPictures/ba-slide-3/underarms5_before.webp';
import bikini2_after from './images/beforeAfterPictures/ba-slide-3/bikini2_after.webp';
import bikini2_before from './images/beforeAfterPictures/ba-slide-3/bikini2_before.webp';
import bikini3_after from './images/beforeAfterPictures/ba-slide-3/bikini3_after.webp';
import bikini3_before from './images/beforeAfterPictures/ba-slide-3/bikini3_before.webp';
import bikini4_after from './images/beforeAfterPictures/ba-slide-3/bikini4_after.webp';
import bikini4_before from './images/beforeAfterPictures/ba-slide-3/bikini4_before.webp';

import bikini5_after from './images/beforeAfterPictures/ba-slide-4/bikini5_after.webp';
import bikini5_before from './images/beforeAfterPictures/ba-slide-4/bikini5_before.webp';
import legs_after from './images/beforeAfterPictures/ba-slide-4/legs_after.webp';
import legs_before from './images/beforeAfterPictures/ba-slide-4/legs_before.webp';
import legs_dark_after from './images/beforeAfterPictures/ba-slide-4/legs_dark_after.webp';
import legs_dark_before from './images/beforeAfterPictures/ba-slide-4/legs_dark_before.webp';
import legs3_after from './images/beforeAfterPictures/ba-slide-4/legs3_after.webp';
import legs3_before from './images/beforeAfterPictures/ba-slide-4/legs3_before.webp';

import arms_after from './images/beforeAfterPictures/ba-slide-5/arms_after.webp';
import arms_before from './images/beforeAfterPictures/ba-slide-5/arms_before.webp';
import neck_after from './images/beforeAfterPictures/ba-slide-5/neck_after.webp';
import neck_before from './images/beforeAfterPictures/ba-slide-5/neck_before.webp';
import shoulder_after from './images/beforeAfterPictures/ba-slide-5/shoulder_after.webp';
import shoulder_before from './images/beforeAfterPictures/ba-slide-5/shoulder_before.webp';

import abs_after from './images/beforeAfterPictures/ba-slide-6/abs_after.webp';
import abs_before from './images/beforeAfterPictures/ba-slide-6/abs_before.webp';
import front_t_after from './images/beforeAfterPictures/ba-slide-6/front_t_after.webp';
import front_t_before from './images/beforeAfterPictures/ba-slide-6/front_t_before.webp';
import front_t2_after from './images/beforeAfterPictures/ba-slide-6/front_t2_after.webp';
import front_t2_before from './images/beforeAfterPictures/ba-slide-6/front_t2_before.webp';
import front_t3_after from './images/beforeAfterPictures/ba-slide-6/front_t3_after.webp';
import front_t3_before from './images/beforeAfterPictures/ba-slide-6/front_t3_before.webp';

import face2_after from './images/beforeAfterPictures/ba-slide-7/face2_after.webp';
import face2_before from './images/beforeAfterPictures/ba-slide-7/face2_before.webp';
import full_back_after from './images/beforeAfterPictures/ba-slide-7/full_back_after.webp';
import full_back_before from './images/beforeAfterPictures/ba-slide-7/full_back_before.webp';

function SalesPresentationHome() {
  let navigate = useNavigate();
  const [index, setIndex] = useState(0);

  return (
    <Carousel
      id='sales-presentation'
      interval={null}
      wrap={false}
      variant='dark'
      onSelect={(i) => setIndex(i)}
      touch={index < 13 || index > 19}
    >
      <Carousel.Item>
        <div className='home-btn m-2'>
          <button
            onClick={() => {
              navigate('/');
            }}
          >
            <FontAwesomeIcon icon={faHouse} className='fa-lg' />
          </button>
        </div>
        <img alt='slide' src={image1} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image2} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image3} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image4} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image5} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image6} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image7} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image8} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image9} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image10} width={'100%'} className='images' />
      </Carousel.Item>

      {/* Video section */}
      <Carousel.Item>
        <div style={{ height: '768px', backgroundColor: '#00a5b4' }}>
          <div className='row d-flex justify-content-center pt-5'>
            <h1
              className='text-center text-uppercase pb-4'
              style={{ color: '#f9fbfb', fontSize: '3.5em' }}
            >
              Laser Hair Removal{' '}
              <span className='hollow-text-white'>In Action</span>
            </h1>
            <video
              // src='https://presentation.milanlaser.com/images/media1.mp4'
              src={demoVideo}
              preload='auto'
              controls
              style={{ width: '700px', height: '100%' }}
              className=''
            ></video>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <img alt='slide' src={image12} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image13} width={'100%'} className='images' />
      </Carousel.Item>

      {/* Before and After photo comparisons */}
      <Carousel.Item>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image14})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '768px' }}
          >
            <div className='row text-center gy-5'>
              <BeforeAfterComparison
                beforeImage={ba_lip_before}
                afterImage={ba_lip_after}
              />
              <BeforeAfterComparison
                beforeImage={face_left_before}
                afterImage={face_left_after}
              />
              <BeforeAfterComparison
                beforeImage={face_right_before}
                afterImage={face_right_after}
              />
              <BeforeAfterComparison
                beforeImage={face_left2_before}
                afterImage={face_left2_after}
              />
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image14})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '768px' }}
          >
            <div className='row text-center gy-5'>
              <BeforeAfterComparison
                beforeImage={ax_before}
                afterImage={ax_after}
              />
              <BeforeAfterComparison
                beforeImage={ax_dark_before}
                afterImage={ax_dark_after}
              />
              <BeforeAfterComparison
                beforeImage={underarms3_before}
                afterImage={underarms3_after}
              />
              <BeforeAfterComparison
                beforeImage={underarms4_before}
                afterImage={underarms4_after}
              />
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image14})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '768px' }}
          >
            <div className='row text-center gy-5'>
              <BeforeAfterComparison
                beforeImage={underarms5_before}
                afterImage={underarms5_after}
              />
              <BeforeAfterComparison
                beforeImage={bikini2_before}
                afterImage={bikini2_after}
              />
              <BeforeAfterComparison
                beforeImage={bikini3_before}
                afterImage={bikini3_after}
              />
              <BeforeAfterComparison
                beforeImage={bikini4_before}
                afterImage={bikini4_after}
              />
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image14})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '768px' }}
          >
            <div className='row text-center gy-5'>
              <BeforeAfterComparison
                beforeImage={bikini5_before}
                afterImage={bikini5_after}
              />
              <BeforeAfterComparison
                beforeImage={legs_dark_before}
                afterImage={legs_dark_after}
              />
              <BeforeAfterComparison
                beforeImage={legs_before}
                afterImage={legs_after}
              />
              <BeforeAfterComparison
                beforeImage={legs3_before}
                afterImage={legs3_after}
              />
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image14})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '768px' }}
          >
            <div className='row text-center gy-5'>
              <BeforeAfterComparison
                beforeImage={legs_dark_before}
                afterImage={legs_dark_after}
              />
              <BeforeAfterComparison
                beforeImage={arms_before}
                afterImage={arms_after}
              />
              <BeforeAfterComparison
                beforeImage={neck_before}
                afterImage={neck_after}
              />
              <BeforeAfterComparison
                beforeImage={shoulder_before}
                afterImage={shoulder_after}
              />
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image14})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '768px' }}
          >
            <div className='row text-center gy-5'>
              <BeforeAfterComparison
                beforeImage={abs_before}
                afterImage={abs_after}
              />
              <BeforeAfterComparison
                beforeImage={front_t_before}
                afterImage={front_t_after}
              />
              <BeforeAfterComparison
                beforeImage={front_t2_before}
                afterImage={front_t2_after}
              />
              <BeforeAfterComparison
                beforeImage={front_t3_before}
                afterImage={front_t3_after}
              />
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image14})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '768px' }}
          >
            <div className='row gx-5 text-center'>
              <BeforeAfterComparison
                beforeImage={full_back_before}
                afterImage={full_back_after}
              />
              <BeforeAfterComparison
                beforeImage={face2_before}
                afterImage={face2_after}
              />
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image15} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image16} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image17} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image18} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image19} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <img alt='slide' src={image20} width={'100%'} className='images' />
      </Carousel.Item>
      <Carousel.Item>
        <div className='home-btn m-2'>
          <button
            onClick={() => {
              navigate('/');
            }}
          >
            <FontAwesomeIcon icon={faHouse} className='fa-lg' />
          </button>
        </div>
        <img alt='slide' src={image21} width={'100%'} className='images' />
      </Carousel.Item>
    </Carousel>
  );
}

export default SalesPresentationHome;
